import * as React from "react";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";

import '../styles/pages/404.scss';
import eventBus from "../utils/eventBus";
import ActionButton from "../components/utils/ActionButton";

const NotFoundPage = ({ pageContext }) => {
  React.useEffect(() => {
    eventBus.dispatch('404');
    return () => {
      eventBus.dispatch('404:end');
    }
  }, [])
  return (
    <main className="bi_404">
      <Helmet htmlAttributes={{ lang: pageContext.locale }}>
        <title>
          {pageContext.locale === "fr"
            ? "Page non trouvée | Les Suites de l'ile Barbe"
            : "Page not found | The Suites of Ile Barbe"}
        </title>
        <meta
          type="description"
          content={
            pageContext.locale === "fr" ? "Page non trouvée" : "Page not Found"
          }
        />
      </Helmet>
      <h1>{pageContext.locale === "en" ? "This page doesn't exist" : "Cette page n'existe pas"}</h1>
      <ActionButton color="brown" type="internal" to='/' children={pageContext.locale === "en" ? 'Return to the home page' : 'Retourner à l\'accueil'}/>
    </main>
  );
};

export default NotFoundPage;
